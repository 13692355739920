<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-overlay :value="overlayDesgarga" >
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p>{{frase}}</p>
                    </div>
                </v-overlay>
                <v-col cols="12" xs="12" sm="12" md="12" class="mx-0 px-0">
                    <v-breadcrumbs :items="itemsLinks">
                        <template v-slot:divider>
                            <v-icon>chevron_right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-card style="border-radius: 20px; background: transparent;" elevation="0">
                        <v-overlay :z-index="999" :value="overlayCargar" :absolute="true">
                            <div class="align-center justify-center text-center">
                                <v-progress-circular
                                    indeterminate
                                    :size="100"
                                    :width="7"
                                    color="#2DB9D1"
                                >
                                    <v-img
                                        src="/static/icon/favicon.ico"
                                        :width="60"
                                    ></v-img>
                                </v-progress-circular>
                                <p class="mt-5">Cargando...</p>
                            </div>
                        </v-overlay>
                        
                        
                        <v-card-text class="px-0 pb-30px pt-6" style="position:relative; background: transparent;">
                            <v-row class="pa-0 ma-0 areaConsulta informacionGeneralConsulta" style="border-radius: 20px; background: transparent;">
                                <v-col 
                                    cols="12" xs="12" sm="12" md="12" 
                                    class="pa-0 " 
                                    style="min-height:500px;"
                                    
                                >
                                    <div >
                                        <ValidationObserver ref="formTabla" v-slot="{ invalid }">
                                            <simple-table
                                                ref="tablaAnual"
                                                :isSelectable="true"
                                                :selected="selected_general"
                                                @selectAllEvent="selectAll"
                                                :rows="datosTabla"
                                                :columns="headers"
                                                :perPage="[10, 25, 50, 100, 1000]"
                                                :tableName="'Lista de cálculo anual de empleados'"
                                                :valCheck="'empleado_id'"
                                                :idTabla="'tablaAnual'"
                                                :loading="loadingProcesar"
                                                @filtroCampo="filtroCampo"
                                            >
                                                <template slot="actionButtonsLeft">
                                                    <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pa-0 d-flex justify-end align-center">
                                                        <span class="exportar-en mr-2">
                                                            Exportar en
                                                        </span>
                                                        <v-btn
                                                            color="#FFF"
                                                            class="elevation-0 pt-0 pb-0 pl-0 pr-0 mr-5"
                                                            @click="guardar(false)"
                                                            height="42"
                                                            width="15"
                                                            min-width="50px"
                                                            style="border-radius: 15px; box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.08);"
                                                            :disabled="datosTabla.length == 0" 

                                                        >
                                                            <img src="/static/icon/xls.png" style="width:25px"/>
                                                        </v-btn>
                                                        <v-menu offset-y content-class="menuClassAcciones">
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                    dark
                                                                    class="btnAcciones pr-1 mr-0"
                                                                    v-bind="attrs"
                                                                    v-on="on"
                                                                    elevation="0"
                                                                >
                                                                    Acciones 
                                                                    <template  v-for="(item,valor) in attrs" >
                                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#FFFFFF'>expand_less</v-icon>
                                                                        <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#FFFFFF'>expand_more</v-icon>
                                                                    </template>
                                                                </v-btn>
                                                                
                                                            </template>
                                                            <div class="btnAccionesContenedor">
                                                                
                                                                <div @click="descargarCartaAviso('masivo')" class="btnAccionesItem py-2">
                                                                    <span>Descargar carta de aviso</span>
                                                                </div>
                                                                <!-- <div @click="descargarConstanciaF37();" class="btnAccionesItem py-2">
                                                                    <span>Descargar constancia F37</span>
                                                                </div> -->
                                                                <div @click="vistaAcumulados('masivo')" class="btnAccionesItem py-2">
                                                                    <span>Vista de acumulados</span>
                                                                </div>
                                                                <div @click="eliminarMasivo()" class="btnAccionesItem py-2">
                                                                    <span>Eliminar seleccionados</span>
                                                                </div>
                                                                    
                                                            </div>
                                                        </v-menu>
                                                        <div
                                                            class="icon-container d-flex align-center justify-center ml-4"
                                                            @click="openModal()"
                                                        >
                                                            <v-icon>mdi-magnify</v-icon>
                                                        </div>
                                                    </v-col>
                                                </template>
                                                <tbody slot="body" slot-scope="{ data }" class="tablaBody">
                                                    <tr :key="empleado.empleado_id" v-for="empleado in data" class="alto-tr">
                                                        <td style="width:115px !important; ">
                                                            <div>
                                                                <v-checkbox class="mb-5" primary hide-details :value="empleado.empleado_id" v-model="selected_general"></v-checkbox>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Calcular anual -->
                                                            <div>
                                                                {{empleado.claveEstatus == '01' ? 'SI':'NO'}}
                                                            </div>
                                                        </td>
                                                        <td class="py-1 my-1">
                                                            <!-- Motivo -->
                                                            <div>
                                                                {{empleado.observaciones}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Clave -->
                                                            <div>
                                                                {{empleado.clave}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Nombre del asalariado -->
                                                            <div>
                                                                {{empleado.empleado}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Estatus -->
                                                            <div>
                                                                {{empleado.fechaBaja == "" ? "Alta":"Baja"}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Fecha alta IMSS -->
                                                            <div>
                                                                {{dateFormat(empleado.fechaAlta)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Fecha baja -->
                                                            <div>
                                                                {{dateFormat(empleado.fechaBaja)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Percepciones totales -->
                                                            <div>
                                                                {{formatNum(parseFloat(empleado.percepcionExcento) + parseFloat(empleado.percepcionGravado))}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Percepciones exentas -->
                                                            <div>
                                                                {{formatNum(empleado.percepcionExcento)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Percepciones gravadas -->
                                                            <div>
                                                                {{formatNum(empleado.percepcionGravado)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Base gravable impuesto anual -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.baseGravadoAnual)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR causado Art. 152 LISR -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrCausado)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- Subsidio causado -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.subsidioCausado)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR anual -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrAnual)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR retenido en el ejercicio -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrRetenidoEjercicio)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR a cargo -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrACargo)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <!-- ISR a favor -->
                                                            <div v-if="empleado.claveEstatus == '01'">
                                                                {{formatNum(empleado.isrAFavor)}}
                                                            </div>
                                                            <div v-else>
                                                                {{formatNum(0)}}
                                                            </div>
                                                        </td>
                                                        
                                                        
                                                        <td>
                                                            <div>
                                                                <v-menu buttom left class="elevation-0 d-inline" content-class="mt-0 menuClassAccionesEmpleado"> 
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <v-btn
                                                                            v-bind="attrs"
                                                                            v-on="on"
                                                                            icon 
                                                                            fab
                                                                            dark
                                                                            small
                                                                            class="elevation-0"
                                                                        >
                                                                            <template  v-for="(item,valor) in attrs" >
                                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'false'" class="pl-2" color='#828282'>more_vert</v-icon>
                                                                                <v-icon :key="valor" v-if="valor == 'aria-expanded' &&  item == 'true'" class="pl-2" color='#2DB9D1'>more_vert</v-icon>
                                                                            </template>
                                                                        </v-btn>
                                                                    </template>

                                                                    <v-list>
                                                                        <v-list-item
                                                                            v-if="empleado.cambioVariable"
                                                                            @click="descargarCartaAviso('empleado', empleado)"
                                                                        >
                                                                            <v-list-item-title>Descargar carta de aviso</v-list-item-title>
                                                                        </v-list-item>
                                                                        <v-list-item
                                                                            v-if="empleado.cambioVariable"
                                                                            @click="abrirModalCargaDocumento(empleado)"
                                                                        >
                                                                            <v-list-item-title>Subir carta de aviso firmada</v-list-item-title>
                                                                        </v-list-item>
                                                                        <v-list-item
                                                                            v-if="empleado.cambioVariable && empleado.url_carta"
                                                                            @click="descargarCartaAvisoFirmada(empleado)"
                                                                        >
                                                                            <v-list-item-title>Descargar carta de aviso firmada</v-list-item-title>
                                                                        </v-list-item>
                                                                        <!-- <v-list-item
                                                                            @click="descargarConstanciaF37()" 
                                                                        >
                                                                            <v-list-item-title>Descargar constancia F37</v-list-item-title>
                                                                        </v-list-item> -->
                                                                        <v-list-item
                                                                            @click="vistaAcumulados('empleado', empleado)"
                                                                        >
                                                                            <v-list-item-title>Vista de acumulados</v-list-item-title>
                                                                        </v-list-item>
                                                                        <v-list-item
                                                                            @click="eliminar(empleado)"
                                                                        >
                                                                            <v-list-item-title>Eliminar</v-list-item-title>
                                                                        </v-list-item>
                                                                    </v-list>
                                                                </v-menu>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </simple-table>
                                        </ValidationObserver>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>


            <template class="borde-card">
                <v-dialog v-model="dialog" persistent max-width="800px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <v-card class="borde-card">
                            <v-card-title class="card_titulo padding_derecho padding_izquierdo pb-5">
                                <div class="headerModal-filter">
                                    <h2 class="titleModalBuscar">{{ "Búsqueda avanzada" }}</h2>
                                </div>
                            </v-card-title>

                            <v-card-text class="card_texto">
                                <div id="padre" v-if="isLoadingModal" class="d-flex justify-center">
                                    <div id="loading">
                                        <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                    </div>
                                </div>
                                
                                <v-container v-if="!isLoadingModal" grid-list-md id="contenedor">
                                    <v-row class="pt-0 mt-4">
                                        
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                label="Empleado"
                                                :items="catalogosEmpleados"
                                                item-text="nombre_completo"
                                                item-value="id"
                                                persistent-hint
                                                v-model="searchEmpleadoId"
                                                class="pa-0 ma-0"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                label="Complemento"
                                                :items="catalogosComplementos"
                                                item-text="descripcion"
                                                item-value="id"
                                                persistent-hint
                                                v-model="searchConceptoId"
                                                class="pa-0 ma-0"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                label="Motivo"
                                                :items="catalogosMotivos"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="searchMotivoId"
                                                class="pa-0 ma-0"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                label="Estatus del empleado"
                                                :items="catalogosEstatus"
                                                item-text="descripcion"
                                                item-value="value"
                                                persistent-hint
                                                v-model="searchEstatusId"
                                                class="pa-0 ma-0"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </v-col>

                                        <v-col v-if="rol == 'root' || rol == 'admin'" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class="pb-0 ma-0">
                                            <v-autocomplete
                                                outlined
                                                label="Empresas"
                                                :items="empresas"
                                                item-text="nombre"
                                                item-value="id"
                                                persistent-hint
                                                v-model="empresaSeleccionada"
                                                class="pa-0 ma-0"
                                                no-data-text="Datos no disponibles"
                                            >
                                            </v-autocomplete>
                                        </v-col>

                                        <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class=" pb-0 ma-0">
                                            <VuetifyMultipleAutocomplete
                                                outlined
                                                :items="sucursales"
                                                item_text="nombre"
                                                @dataMultiple="(data)=> sucursalesSeleccionadas = data"
                                                label="Sucursales"
                                                ref="multSucursal"
                                            />
                                        </v-col>

                                        <v-col v-if="rol == 'root' || rol=='admin' || rol=='admin-empresa'" cols="12" xs="12" sm="6" md="6" lg="6" xl="6" class=" pb-0 ma-0">
                                            <VuetifyMultipleAutocomplete
                                                outlined
                                                :items="tipos_nominas"
                                                item_text="claveDescripcion"
                                                @dataMultiple="(data)=> tiposNominasSeleccionadas = data"
                                                label="Tipo de nómina"
                                                ref="multTiposNomina"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSearch"
                                >
                                    Cerrar
                                </button>
                                <v-btn
                                    class="btnGuardar"
                                    @click="buscar()"
                                    :loading="isSearch"
                                >
                                    Buscar
                                </v-btn>
                            </v-card-actions>
                    </v-card>
                </v-dialog>
            </template>

            


            <v-dialog v-model="dialogCargaDocumento" persistent max-width="500px">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalCargaDocumento"></v-btn>
                </template>
                <v-card>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <v-card-title class="justify-center card_titulo">
                            <div class="headerModal">
                                <h2 class="titleModal">{{ titulo_modal }}</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto">
                            <v-container grid-list-md id="contenedor" class="pt-0">
                                <v-divider class="divider"></v-divider>
                                <v-row>
                                    <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                                        <Upload
                                            :tamanioArchivo="10485760" 
                                            :multiple="false"
                                            :arrayFile="archivo.archivo"
                                            @vModelFile="(data)=> archivo.archivo = data"
                                        >
                                        </Upload>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button class="btnCerrar" @click="cerrarModalDocumento()" :disabled="isSavingDocument">Cerrar</button>
                            <v-btn class="btnGuardar" @click="guardarDocumento()" :disabled="invalid" :loading="isSavingDocument">Subir</v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
//APIS
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import apiAnios from '@/api/anual_fiscal';
import apiRegistroPatronal from "@/api/nominas/registroPatronal";
import sucursalApi from "@/api/sucursales";
import apiTipoNomina from '@/api/nominas/tipoNomina';
import apiAjusteAnual from "@/api/nominas/ajusteAnual";

//COMPONENTS
import VuetifyMultipleAutocomplete from '@/components/VuetifyMultipleAutocomplete.vue';
import DatatableSimple from '@/components/simpletable/Datatable.vue';
import Upload from "@/components/DragAndDrop.vue";

//PLUGINS
import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";

export default {
    components:{
        VuetifyMultipleAutocomplete,
        "simple-table": DatatableSimple,
        Upload
    },
    data(){
        return {
            datosLogin                          : null,
            rol                                 : 'root',

            clientes                            : [],
            registrosPatronales                 : [],
            empresas                            : [],
            tipos_nominas                       : [],
            items_anios                         : null,
            nominas                             : [],
            empleados                           : [],

            cliente_value                       : null,
            registrosPatronalesSeleccionados    : [],
            empresaSeleccionada                 : null,
            anio_id                             : null,
            empleadosSeleccionados              : [],

            overlayCargar                       : false,
            btnGuardar                          : false,
            isSearch                            : false,
            datosTabla                          : [],
            loadingProcesar                     : false,
            selected_general                    : [],
            masivo                              : false,

            headers: [
                { label: "Calcular anual",                  name: "calcular",       filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Motivo",                          name: "motivo",         filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Clave",                           name: "clave",          filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Nombre del empleado",             name: "empleado",       filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Estatus",                         name: "estatus",        filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Fecha alta IMSS",                 name: "fecha_alta",     filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Fecha baja",                      name: "fecha_baja",     filterable: true,       showTooltip: false,     align: "left",    mensajeTooltip:"" },
                { label: "Percepciones totales",            name: "per_total",      filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"Percepciones totales del empleado<br> acumulado al periodo actual. Este importe es<br> considerado para validar si el empleado<br> supero el tope de 400,000 anuales." },
                { label: "Percepciones exentas",            name: "per_excenta",    filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"Percepciones exentas del empleado<br> acumulado al periodo actual." },
                { label: "Percepciones gravadas",           name: "per_gravada",    filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"Percepciones gravadas del empleado<br> acumulado al periodo actual." },
                { label: "Base gravable impuesto anual",    name: "imp_anual",      filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"Importe gravable a trabajadores que aplique<br> cálculo anual conforme al articulo 97 de LISR" },
                { label: "ISR causado Art. 152  LISR",      name: "isr_causado",    filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"Tarifa de impuesto anual que le corresponde<br> al empleado de acuerdo a las percepciones<br> gravadas en el ejercicio." },
                { label: "Subsidio causado",                name: "sub_causado",    filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"Subsidio que le correspondió al cierre del ejercicio considerando el ajuste al subsidio causado." },
                { label: "ISR anual",                       name: "isr_anual",      filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"Impuesto anual: ISR causado menos subsidio<br> causado." },
                { label: "ISR retenido en el ejercicio",    name: "isr_ret_ejer",   filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"ISR retenido al cierre del ejercicio considerando<br> el reintegro de ISR " },
                { label: "ISR a cargo",                     name: "isr_cargo",      filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"Impuesto a cargo del ejercicio, resulta<br> cuando el ISR anual es mayor al ISR retenido<br> en el ejercicio." },
                { label: "ISR a favor",                     name: "isr_favor",      filterable: true,       showTooltip: true,      align: "left",    mensajeTooltip:"Impuesto a favor del ejercicio, resulta cuando<br> el  ISR Retenido en el ejercicio es mayor al ISR<br> anual." },
                { label: "Opciones",                        name: "opciones",       filterable: false,      showTooltip: false,     align: "left",    mensajeTooltip:"" }
            ],
            itemsMotivos: [
                {
                    nombre: 'Aplica calculo',
                    valor: '01'
                },
                {
                    nombre: 'Empleado comunico escrito de no calcular anual',
                    valor: '06'
                },

            ],
            itemsDeducciones            : [],
            itemsPercepciones           : [],
            valorDeduccion              : null,
            valorPercepcion             : null,
            valorAnio                   : null,
            overlayDesgarga             : false,
            frase                       : "Guardando",
            calculoAnual                : null,
            itemsLinks      : [
                {
                    text: 'Lista de cálculos anuales',
                    disabled: false,
                    to: 'listaCalculoAnual',
                },
                {
                    text: 'Lista de cálculo anual de empleados',
                    disabled: true,
                    to: '/detalle'
                },
            ],
            catalogosEmpleados      : [],
            catalogosComplementos   : [],
            catalogosMotivos        : [],
            catalogosEstatus        : [
                {
                    descripcion : 'Alta',
                    value       : true,
                },
                {
                    descripcion : 'Baja',
                    value       : false,
                }
            ],
            searchEmpleadoId            : null,
            searchEstatusId             : null,
            searchMotivoId              : null,
            searchConceptoId            : null,
            searchClienteId             : null,
            searchRP                    : null,
            
            sucursales                  : [],
            sucursalesSeleccionadas     : [],
            tipos_nominas               : [],
            tiposNominasSeleccionadas   : [],
            isLoadingModal              : false,
            dialog                      : false,
            parametrosGenerales         : { 
                paginate                : false,
                ajuste_id               : null,
                empleado_id             : null,
                estatus_id              : null,
                motivo_id               : null,
                concepto_nomina_id      : null,
                empresaSeleccionada         : null,
                sucursalesSeleccionadas     : null,
                tiposNominasSeleccionadas   : null,
            },


            dialogCargaDocumento        : false,
            isSavingDocument            : false,
            urlImg                      : null,
            archivo         : {
                archivo                 : [],
                ajuste_id               : null,
                empleado_id             : null,
                tipo                    : 'ajuste_anual'
            }
            
}
    },
    watch:{
        
        empresaSeleccionada:function(val){
            this.cambiaEmpresas(val);
            this.getTiposNominas();
        },
        anio_id:function(val){
            this.getEmpleados();
        },
        selected_general:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
    },
    methods:{
        /**
         * @method getClientes Este método realiza la petición a la API de clientes.
         * @description Este método se ejecuta al seleccionar al iniciar la vista. El resultado se almacena en el array clientes=[]
        */
        async getClientes() {
            let param = { 
                activo: true, 
                paginate: false 
            };
            await clientesApi.getClientes(param).then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        async cambiaCliente(cliente){
            if(cliente == null){
                return;
            }

            let param = { 
                activo: true, 
                paginate: false, 
                cliente_id: cliente 
            };
            await apiRegistroPatronal.find(param).then(response => {
                //this.registrosPatronales = response.data;
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.nombreClave = item.nombre + ' - ' + item.clave
                        this.registrosPatronales.push(item);
                    })
                }
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
            });
        },

        /**
         * @method cambiaRegistroPatronal Este método realiza la petición a la API de Empresas.
         * @description Este método se almacena en el array empresas=[]. Se ejecuta al seleccionar al menos una empresa.
        */
        async cambiaRegistroPatronal(){

            let registrosPatronalesSeleccionados = [];
            registrosPatronalesSeleccionados.push(this.searchRP)
            let param = {   
                activo: true, 
                paginate: false, 
                cliente_id: this.searchClienteId,
                arrayRegistroPatronal: registrosPatronalesSeleccionados.length == 0 ? null:JSON.stringify(registrosPatronalesSeleccionados)
            };

            if(registrosPatronalesSeleccionados.length == 0){
                this.empresas = []
                return
            }

            await empresaApi.getEmpresas(param).then(response => {
                this.empresas = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method getAnios Este método realiza la petición a la API de años.
         * @description Este método se ejecuta al seleccionar al menos una empresa. El resultado se almacena en el array items_anios=[]
        */
        async getAnios() {
            if(this.empresaSeleccionada == null){
                this.items_anios = []
                return
            }
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.cliente_value,
                empresa_id  : this.empresaSeleccionada
            }

            await apiAnios.find(param).then((response) => {
                this.items_anios = response.data;
                this.items_anios.map(emp => emp.nombreAnio = emp.anio.nombre);
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los años.");
            })
        },


        /**
         * @method getEmpleados Este método realiza la petición a la API de empleados
         * @description Realiza petición a la API de empleados cuando la empresa es seleccionada y el resultado lo almacena en el array empleados[].
         * Si la petición no se realiza se manda un mensaje al usuario indicandole que no se pudo realizar la petición.
        */
        getEmpleados(){
            let arrayEmpresas = [];
            arrayEmpresas.push(this.empresaSeleccionada);


            let parametros = { 
                anio_fiscal_id : this.anio_id,
                empresa_id : arrayEmpresas,
                registro_patronal_id : this.registrosPatronalesSeleccionados
            };

            if(this.registrosPatronalesSeleccionados.length == 0){
                this.empleados = []
                return
            }

            apiAjusteAnual.getEmpleados(parametros).then((response)=>{
                this.empleados = response.data;
                //this.empleados.map(emp => emp.nombre_completo += " - "+emp.nombre_empresa + " - "+emp.nombre_sucursal + " - "+emp.nombre_departamento);
            })
            .catch(error => {
                 Notify.ErrorToast("No se pudo realizar la petición de empleados.");
            });
        },


        async procesar(
            ajuste_id, empleado_id      = null, 
            estatus_id                  = null, 
            motivo_id                   = null, 
            concepto_nomina_id          = null,
            empresaSeleccionada         = null,
            sucursalesSeleccionadas     = null,
            tiposNominasSeleccionadas   = null,
            order_by                    = null, 
            order                       = null
        ){
            
            let empresaId = [];
            let sucursalesIds = [];
            let tiposNominasIds = [];

            if(empresaSeleccionada != null){
                empresaId.push(empresaSeleccionada)
            }

            if(sucursalesSeleccionadas != null){
                sucursalesIds = sucursalesSeleccionadas
            }

            if(tiposNominasSeleccionadas != null){
                tiposNominasIds = tiposNominasSeleccionadas
            }

            

            let parametros = { 
                paginate                    : false,
                ajuste_id                   : ajuste_id,
                empleado_id                 : empleado_id,
                estatus_id                  : estatus_id,
                motivo_id                   : motivo_id,
                concepto_nomina_id          : concepto_nomina_id,
                empresa_id                  : empresaId?.length == 0 ? null:JSON.stringify(empresaId),
                sucursal_id                 : null,
                tipo_nominas                : null,
                //sucursal_id                 : sucursalesSeleccionadas?.length == 0 ? nulo:JSON.stringify(sucursalesSeleccionadas), 
                //tipo_nominas                : tiposNominasSeleccionadas?.length == 0 ? nulo:JSON.stringify(tiposNominasSeleccionadas),
                order_by                    : order_by,
                order                       : order
            };
            if(sucursalesIds.length != 0){
                parametros.sucursal_id = JSON.stringify(sucursalesIds)
            }
            if(tiposNominasIds.length != 0){
                parametros.tipo_nominas = JSON.stringify(tiposNominasIds)
            }

            this.parametrosGenerales = { 
                paginate                    : false,
                ajuste_id                   : ajuste_id,
                empleado_id                 : empleado_id,
                estatus_id                  : estatus_id,
                motivo_id                   : motivo_id,
                concepto_nomina_id          : concepto_nomina_id,
                empresaSeleccionada         : empresaSeleccionada,
                sucursalesSeleccionadas     : sucursalesSeleccionadas,
                tiposNominasSeleccionadas   : tiposNominasSeleccionadas,
            };

            this.loadingProcesar = true;

            await apiAjusteAnual.findDetalle(parametros).then((response)=>{
                this.datosTabla                     = response.data;
                this.loadingProcesar = false;
            })
            .catch(err => {
                this.loadingProcesar = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });
        },
        async filtroCampo(columnaOrden){
            await this.procesar(
                this.calculoAnual.id, 
                this.parametrosGenerales.empleado_id,
                this.parametrosGenerales.estatus_id ,
                this.parametrosGenerales.motivo_id,
                this.parametrosGenerales.concepto_nomina_id,
                this.parametrosGenerales.empresaSeleccionada,
                this.parametrosGenerales.sucursalesSeleccionadas,
                this.parametrosGenerales.tiposNominasSeleccionadas,
                columnaOrden.order_by,
                columnaOrden.dir
            );
        },
        async guardar(guardar){
            let self                = this;
            let url                 = "calculo-nomina/guardar-ajuste-anual";
            this.overlayDesgarga    =  true;
            this.frase              = "Descargando";
            const FileDownload      = require("js-file-download");
            let nombre              = "calculo-anual.xlsx";
            
            this.datosTabla.forEach( item => {
                if(item.claveEstatus == '06'){
                    item.aplicaCalculo = false;
                }
            })
            let parametros          = { 
                anio_fiscal_id  : this.valorAnio, 
                cargo_id        : this.valorDeduccion,
                favor_id        : this.valorPercepcion,
                registros       : this.datosTabla,
                guardar         : guardar
            }
            const object = parametros;

            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object,
                })
                .then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.overlayDesgarga = false;
                })
                .catch(async (error)=>{
                    self.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (err) {
                this.overlayDesgarga = false;
                Notify.ErrorToast("No se pudo realizar la petición.");
            }
        },

        /**
         * @method dateFormat Esta método parsea una fecha
         * @description Convierte una fecha de formato DD-MM-AAAA => DD/MM/AAAA. Se usa para el modal y la tabla 
         * @param {string} fecha formato DD-MM-AAAA
         * @return {string} devuelve un string en formato DD/MM/AAAA
        */
        dateFormat(fecha) {
            if(fecha == 'null' || fecha == ""){
                return
            }

            let fecha2 = fecha.split("-");
            return fecha2[0] + " / " + fecha2[1]+ " / " + fecha2[2];
        },

        /**
         * @method formatNum Esta método parsea un número
         * @description Convierte un número a formato moneda, formato pesos mexicanos 
         * @param {number} num Valor númerico que puede tener números decimales.
         * @return {string} devuelve un string en formato $0.00
        */
        formatNum(numero){
            let num = parseFloat(numero)
            const formatter = new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            })
            return formatter.format(num);
        },
        selectAll(isSelected, items) {
            this.selected_general = items;
        },
        async descargarCartaAviso(tipo, empleado = null){
            let self                = this;
            let url                 = "reportes/no-calculo-anual";
            this.overlayDesgarga    =  true;
            this.frase              = "Descargando";
            const FileDownload      = require("js-file-download");
            let nombre              = '';
            let parametros          = {};

            let empleadosTabla      = [];
            let idEmpleados         = [];


            if(tipo == 'masivo' && this.selected_general.length > 1){
                nombre              = "Carta_de_aviso.zip";
                empleadosTabla = await this.datosTabla.filter((empleado) => this.selected_general.includes(empleado.empleado_id) && (empleado.cambioVariable))
                if(empleadosTabla.length == 0 ){
                    Notify.ErrorToast("Los empleados seleccionados no se les puede descargar las cartas de aviso.");
                    this.overlayDesgarga    =  false;
                    return;
                }
                else if(empleadosTabla.length == 1){
                    nombre              = `${empleadosTabla[0].empleado}.docx`;
                    idEmpleados = empleadosTabla.map(element => { return element.empleado_id})

                    parametros          = { 
                        anio_fiscal_id  : this.valorAnio, 
                        empleados       : idEmpleados
                    }
                }
                else{
                    idEmpleados = empleadosTabla.map(element => { return element.empleado_id})

                    parametros          = { 
                        anio_fiscal_id  : this.valorAnio, 
                        empleados       : idEmpleados
                    }
                }   
            }
            else if(tipo == 'masivo' && this.selected_general.length == 1){
                
                let datos           = await this.datosTabla.find(element => element.empleado_id === this.selected_general[0]);
                nombre              = `${datos.empleado}.docx`;
                if(datos.cambioVariable == false){
                    Notify.ErrorToast("El empleado seleccionado no se le puede descargar la carta de aviso.");
                    this.overlayDesgarga    =  false;
                    return;
                }
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : this.selected_general
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 0){
                Notify.ErrorToast("Se requiere seleccionar al menos un empleado");
                this.overlayDesgarga    = false;
                return;
            }
            else if(tipo == 'empleado'){
                nombre              = `${empleado.empleado}.docx`;
                let itemEmpleado = [];
                itemEmpleado.push(empleado.empleado_id);
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : itemEmpleado
                }
            }
            
            const object = parametros;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object,
                })
                .then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.overlayDesgarga = false;
                })
                .catch(async (error)=>{
                    self.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (err) {
                this.overlayDesgarga = false;
                Notify.ErrorToast("No se pudo realizar la petición.");
            }
        },
        async descargarConstanciaF37(){
            let self                = this;
            let url                 = "reportes/constanciaF37";
            this.overlayDesgarga    =  true;
            this.frase              = "Descargando";
            const FileDownload      = require("js-file-download");
            let nombre = '';
            let parametros = {};


            this.overlayDesgarga    =  false; // quitar esta linea
            return; // quitar esta linea


            if(tipo == 'masivo' && this.selected_general.length > 1){
                nombre              = "Carta_de_aviso.zip";
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : this.selected_general
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 1){
                
                let datos           = await this.datosTabla.find(element => element.empleado_id === this.selected_general[0]);
                nombre              = `${datos.empleado}.docx`;
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : this.selected_general
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 0){
                Notify.ErrorToast("Se requiere seleccionar al menos un empleado");
                this.overlayDesgarga    = false;
                return;
            }
            else if(tipo == 'empleado'){
                nombre              = `${empleado.empleado}.docx`;
                let itemEmpleado = [];
                itemEmpleado.push(empleado.empleado_id);
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    empleados       : itemEmpleado
                }
            }
            
            const object = parametros;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object,
                })
                .then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.overlayDesgarga = false;
                })
                .catch(async (error)=>{
                    self.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (err) {
                this.overlayDesgarga = false;
                Notify.ErrorToast("No se pudo realizar la petición.");
            }
        },
        async vistaAcumulados(tipo, empleado = null){
            let self                = this;
            let url                 = "calculo-nomina/reporte-ajuste-anual-detallado";
            this.overlayDesgarga    =  true;
            this.frase              = "Descargando";
            const FileDownload      = require("js-file-download");
            let nombre = '';
            let parametros = {};

            let empleadosTabla      = [];


            if(tipo == 'masivo' && this.selected_general.length > 1){
                nombre              = "Vista_acumulados.zip";
                empleadosTabla      = await this.datosTabla.filter((empleado) => this.selected_general.includes(empleado.empleado_id))
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio,
                    registros       : empleadosTabla,
                    guardar         : false
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 1){
                
                empleadosTabla      = await this.datosTabla.filter((empleado) => this.selected_general.includes(empleado.empleado_id))
                nombre              = `${empleadosTabla[0].empleado}.xlsx`;
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio,
                    registros       : empleadosTabla,
                    guardar         : false
                }
            }
            else if(tipo == 'masivo' && this.selected_general.length == 0){
                Notify.ErrorToast("Se requiere seleccionar al menos un empleado");
                this.overlayDesgarga    = false;
                return;
            }
            else if(tipo == 'empleado'){
                nombre              = `${empleado.empleado}.xlsx`;
                let itemEmpleado = [];
                itemEmpleado.push(empleado);
                parametros          = { 
                    anio_fiscal_id  : this.valorAnio, 
                    registros       : itemEmpleado,
                    guardar         : false
                }
            }
            
            const object = parametros;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object,
                })
                .then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.overlayDesgarga = false;
                })
                .catch(async (error)=>{
                    self.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (err) {
                this.overlayDesgarga = false;
                Notify.ErrorToast("No se pudo realizar la petición.");
            }
        },
        eliminar(data){
            let idRegistros = [];
            idRegistros.push(data.id)
            let parametros = {
                ajuste_id : idRegistros
            };


            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar el registro del empleado " + data.empleado + "?",
                "Eliminar",
                () => {
                    apiAjusteAnual.eliminarEmpleadoDetalleMasivo(parametros).then(response => {
                        Notify.Success("Registro eliminado", "El registro ha sido eliminado satisfactoriamente");
                        this.procesar(this.calculoAnual.id);
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        async eliminarMasivo(){

            if(this.selected_general.length == 0){
                Notify.ErrorToast("Se requiere seleccionar al menos un empleado");
                return;
            }
            let empleadosTabla = [];
            empleadosTabla = await this.datosTabla.filter((empleado) => this.selected_general.includes(empleado.empleado_id));

            let idRegistros = await empleadosTabla.map(element => { return element.id});

            let parametros = {
                ajuste_id : idRegistros
            };

            Notify.Alert(
                "ADVERTENCIA",
                "¿Estás seguro que deseas eliminar los registros seleccionados?",
                "Eliminar",
                () => {
                    apiAjusteAnual.eliminarEmpleadoDetalleMasivo(parametros).then(response => {
                        Notify.Success("Registros eliminados", "Los registros seleccionados han sido eliminado satisfactoriamente");
                        this.procesar(this.calculoAnual.id);
                    })
                    .catch(err => {
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                }
            )
        },
        async openModal(){
            this.isLoadingModal = true;
            this.dialog = true;
            await this.getCatalogos();
            await this.cambiaRegistroPatronal();
        },
        async buscar(){
            this.isSearch = true;
            await this.procesar(
                this.calculoAnual.id, 
                this.searchEmpleadoId, 
                this.searchEstatusId , 
                this.searchMotivoId, 
                this.searchConceptoId,
                this.empresaSeleccionada,
                this.sucursalesSeleccionadas,
                this.tiposNominasSeleccionadas,

            );
            await this.cerrarModal();
            this.isSearch = false;

        },
        async cerrarModal(){
            this.catalogosEmpleados     = [];
            this.catalogosComplementos  = [];
            this.catalogosMotivos       = [];
            this.searchEmpleadoId       = null;
            this.searchEstatusId        = null;
            this.searchMotivoId         = null;
            this.searchConceptoId       = null;
            this.empresaSeleccionada    = null;
            this.sucursalesSeleccionadas= [];
            this.tiposNominasSeleccionadas=[]
            this.dialog = false;
        },
        async getCatalogos(){
            await apiAjusteAnual.getCatalogos(this.calculoAnual.id).then(response => {
                this.catalogosEmpleados     = response.empleados;
                this.catalogosComplementos  = response.complementos;
                this.catalogosMotivos       = response.motivos;
                this.isLoadingModal         = false;
            })
            .catch(err => {
                this.isLoadingModal = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }

                    Notify.ErrorToast(leyenda);
                }
            });
        },

        async cambiaEmpresas(item) {
            if(item == null){
                this.sucursales = [];
                return;
            }
            let arrayEmpresas = [];
            arrayEmpresas.push(item);
            let parametros = { activo: true, paginate: false, arrayEmpresa: JSON.stringify(arrayEmpresas)};
            await sucursalApi.getSucursales(parametros).then(response => {
                this.sucursales = response.data;
                this.sucursales.map(suc => suc.nombre += " - "+suc.nombre_empresa);
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
            });
        },
        async getTiposNominas() {
            if(this.empresaSeleccionada == null){
                this.tipos_nominas = [];
                return
            }
            let arrayEmpresas = [];
            arrayEmpresas.push(this.empresaSeleccionada);
            let param = {
                activo      : true,
                paginate    : false,
                cliente_id  : this.searchClienteId,
                arrayEmpresa: JSON.stringify(arrayEmpresas)
            }
            this.tipos_nominas = [];
            await apiTipoNomina.find(param).then((response) => {
                this.tipos_nominas = response.data;
                this.tipos_nominas.map(tipo_nomina => tipo_nomina.claveDescripcion = tipo_nomina.clave + ' - ' + tipo_nomina.descripcion+ " - "+tipo_nomina.nombre_empresa);
            })
            .catch (err => {
                console.log(err);
                Notify.ErrorToast("No se pudieron cargar los tipos de nómina");
            })
        },

        abrirModalCargaDocumento(item){
            this.dialogCargaDocumento = true;
            this.titulo_modal = 'Cargar carta de aviso firmada';
            
            this.archivo.ajuste_id = item.id;
            this.archivo.empleado_id = item.empleado_id;
        },
        cerrarModalDocumento(){
            this.dialogCargaDocumento    = false;
            this.isSavingDocument        = false;
            this.archivo         = {
                archivo                 : [],
                ajuste_id               : null,
                empleado_id             : null,
                tipo                    : 'ajuste_anual'
            };
        },
        guardarDocumento(){
            if (this.archivo.archivo.length == 0) {
                Notify.Warning("ADVERTENCIA", "No se ha seleccinado un archivo");
                return;
            }
            this.isSavingDocument = true;
            let formData = new FormData();
            formData.append("archivo", this.archivo.archivo[0]);
            formData.append("ajuste_id", this.archivo.ajuste_id.toString());
            formData.append("empleado_id", this.archivo.empleado_id.toString());
            formData.append("tipo", this.archivo.tipo.toString());


            apiAjusteAnual.addDocumento(formData).then((response) => {
                Notify.Success("Operación exitosa", "El documento se guardó satisfactoriamente.");
                this.cerrarModalDocumento();
                this.procesar(this.calculoAnual.id)
            })
            .catch((err) => {
                this.isSavingDocument = false;
                if(typeof err.response.data.error === 'string'){
                    Notify.ErrorToast(err.response.data.error);
                }else{
                    let error = Object.values(err.response.data.error);
                    let leyenda = ``;

                    for (var i = 0; i < error.length; i++) {
                        leyenda+= `* ` + error[i] + `\n`;
                    }
                    Notify.ErrorToast(leyenda);
                }
            });

        },

        async descargarCartaAvisoFirmada(item){
            let self                = this;
            let url                 = "download";
            this.overlayDesgarga    =  true;
            this.frase              = "Descargando";
            const FileDownload      = require("js-file-download");
            let nombre              = '';
            let parametros          = { url: item.url_carta};

            let nombreArchivo = null;
            let archivo_extension = null;

            nombreArchivo = item.url_carta.split("/");
            archivo_extension = nombreArchivo[nombreArchivo.length - 1].split(".")
            nombre = item.empleado + "_carta_firmada." + archivo_extension[archivo_extension.length - 1];

            const object = parametros;
            try {
                await Vue.axios({
                    method: "POST",
                    responseType: "blob",
                    url: url,
                    data: object,
                })
                .then((response, status, xhr) => {
                    FileDownload(response.data, nombre);
                    self.overlayDesgarga = false;
                })
                .catch(async (error)=>{
                    self.overlayDesgarga = false;
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }; 
                });
            } catch (err) {
                this.overlayDesgarga = false;
                Notify.ErrorToast("No se pudo realizar la petición.");
            }
        },

         /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin, admin-empresa
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },
    },
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.calculoAnual = this.$session.get('calculoAnual');
        this.searchClienteId = this.calculoAnual.cliente_id;
        this.searchRP = this.calculoAnual.registro_patronal_id;
        this.procesar(this.calculoAnual.id)
        this.valorAnio = this.calculoAnual.anio_fiscal_id;
        this.urlImg = GetUrlImg.getURL();
    },
    mounted(){
        //if(document.querySelector('#tablaAnual')){
        //    document.querySelector('.emptyTable').style.width     = "3000px";
        //    document.querySelector('.emptyTable').style.minWidth   = "3000px";
        //}
    },
    updated(){
    }
}
</script>

<style scoped>
    .tablaBody{
        width: 4700px;
        max-height: none;
        min-width: 4000px;
    }
    .margen {
		height: 48px;
		margin-bottom: 20px;
		margin-left: 1px;
	}

    .btnGeneral {
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        background-color: #1E2245 !important;
        border-radius: 30px !important;
        color: white !important;
        height: 40px !important;
        outline: none;
        text-transform: none !important;
        margin-left: 3%;
        transition: all .2s;
    }

    .areaConsulta{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 37px;
        color: #828282;
    }

    .informacionGeneralConsulta{
        border: 1px solid transparent;
        box-sizing: border-box;
        border-radius: 15px;
    }



    

    .exportar-en{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #828282;
    }
    .custom-row-true{
        background: #FFCDD2;
    }
    .custom-row-false{
        background: none;
    }

    .v-btn-hover:hover:before {
      background-color: transparent;
      
    }
    .v-btn-hover:before {
        display: none;
    }
    .custom-border{
        border-width:1px; 
        border-color:#E0E0E0 ;
        border-right-style: solid;
    }


    .menuClassAcciones{
        /* box-shadow: none; */
        border-radius: 10px!important;
        margin-top:15px;
    }
    .btnAcciones {
        width: 135px !important;
        height: 42px !important;
        border-radius: 30px;
        text-transform: capitalize;
        background:#58C5D8 !important;
        color:#FFFFFF !important;
        font-family: "Montserrat" !important;
        font-style: normal !important;
        font-weight: 600 !important;
        font-size: 14px !important;
        line-height: 17px !important;
    }
    .btnAccionesContenedor {
        width: 300px;
        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        background-color: #FFF;
    }
    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .menuClassAccionesEmpleado{
        border-radius: 10px!important;
    }

    .divider-gray{
        margin-left:-1px;
        border: 1.5px solid #E0E0E0;
        background:#E0E0E0;
    }
    .skiny{
        margin-top:1px;
        border: .5px solid #E0E0E0;
    }

    .icon-container {
        width: 42px;
        height: 42px;
        background: #ffffff;
        border-radius: 100%;
        text-align: center;
        padding: 11px;
        transition:0.3s;
        }

    .icon-container:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        
    }
    .titleModalBuscar {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #1E2245;
        padding-bottom: 25px;
    }
</style>